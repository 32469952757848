<template>
  <div class="keywords-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-2">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getKeywords"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-10">
                <div class="dataTables_filter text-md-right">
                  <b-button
                    variant="primary"
                    type="button"
                    size="sm"
                    @click="newKeyword"
                  >
                    <i class="uil uil-plus"></i> New Keyword
                  </b-button>
                  <div class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </div>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="getKeywords"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loadingKeywords">
              <b-table
                :items="keywords"
                :fields="datatable.columns"
                :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc"
                :sort-direction="datatable.queries.sort_direction"
                :per-page="datatable.queries.per_page"
                :no-local-sorting="true"
                @sort-changed="sortChanged"
              >
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
                <template v-slot:cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:cell(double_optin)="data">
                  <feather
                    v-if="data.item.double_optin"
                    type="check"
                    class="icon-dual-success"
                  />
                  <feather
                    v-else
                    type="x"
                    class="icon-dual-danger"
                  />
                </template>
                <template v-slot:cell(double_optin_email)="data">
                  <feather
                    v-if="data.item.double_optin_email"
                    type="check"
                    class="icon-dual-success"
                  />
                  <feather
                    v-else
                    type="x"
                    class="icon-dual-danger"
                  />
                </template>

                <template v-slot:cell(actions)="data">
                  <b-dropdown
                    v-if="data.item.editable"
                    variant="black"
                    right
                    toggle-class="arrow-none text-muted pr-0"
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-v"></i>
                    </template>

                    <b-dropdown-item
                      @click="edit(data.item)"
                    >
                      <i class="uil uil-edit mr-2"></i>Edit
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="loadingRemove === data.item.id"
                      variant="danger"
                      @click="destroy(data.item)"
                    >
                      <i class="uil uil-trash mr-2"></i> Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalKeywords }} keywords
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalKeywords"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="new-keyword" v-model="showKeywordModal" :title="selectedKeyword ? 'Edit Keyword #' + selectedKeyword.id : 'New Keyword'" hide-footer>
      <ValidationObserver
        v-slot="{ handleSubmit, invalid, touched }"
        ref="keywordForm"
      >
        <form
          v-if="keyword"
          class="keyword-form"
          @submit.prevent="handleSubmit(submitData)"
        >
          <div class="row">
            <div class="col-md-12">
              <TextInput v-model="keyword.name" label="Name" rules="required" name="name"/>
            </div>
            <div class="col-md-12 mt-2">
              <label class="font-weight-bold">Optin Process</label><br />
              <span>Choose how people subscribe to the list. <b class="text-primary">Double opt-in is recommended</b> to help prevent invalid and
                mailcious contacts from subscribing.</span>
              <div class="mt-2 pt-2">
                <b-form-radio v-model="keyword.double_optin" name="type" :value="true"
                  class="d-block d-flex align-items-center">Double opt-in (recommended)<br />
                  <small>Require new subscribers to confirm their subscription before being added to the
                    list</small></b-form-radio>
                <b-form-radio v-model="keyword.double_optin" name="type" :value="false" class="d-block mt-3">Single
                  opt-in<br /><small>Immediately subscribe people to the list without a
                    confirmation</small></b-form-radio>
              </div>
            </div>
            <div class="col-md-12 mt-2">
              <label class="font-weight-bold">Email Optin Process</label><br />
              <span>Choose how people subscribe to the list. <b class="text-primary">Double opt-in is recommended</b> to help prevent invalid and
                mailcious contacts from subscribing.</span>
              <div class="mt-2 pt-2">
                <b-form-radio v-model="keyword.double_optin_email" name="double_optin_email" :value="true"
                  class="d-block d-flex align-items-center">Double opt-in (recommended)<br />
                  <small>Require new subscribers to confirm their subscription before being added to the
                    list</small></b-form-radio>
              <div v-if="keyword.double_optin_email" class="col-md-12 small-checkbox mt-2">
                <label for="">Redirect URL <span v-b-tooltip.hover class="cursor-pointer" title="User will be redirected to this URL after clicking on confirmation link." style="vertical-align: middle;">
                  <i  class="uil uil-question-circle"></i>
                </span></label>
                <TextInput v-model="keyword.optin_email_redirect_url" rules="url" name="redirect_url" />
              </div>
                <b-form-radio v-model="keyword.double_optin_email" name="double_optin_email" :value="false" class="d-block mt-4">Single
                  opt-in<br /><small>Immediately subscribe people to the list without a
                    confirmation</small></b-form-radio>
              </div>
            </div>

          </div>
          <b-form-group class="text-right">
            <b-button
              variant="primary"
              :disabled="loadingSubmit || (invalid && touched)"
              type="submit"
            >
              <b-spinner v-if="loadingSubmit" small />
              <span v-else>{{ selectedKeyword ? 'Update' : 'Create' }}</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Keyword">
      <p v-if="selectedKeyword"
        >Are you sure you want to delete keyword <b>"{{
          selectedKeyword.name
        }}"</b>?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {
  },

  data() {
    return {
      datatable: {
        columns: [
          { key: 'name', label: 'Keyword' },
          { key: 'double_optin', label: 'Double Optin' },
          { key: 'double_optin_email', label: 'Email Double Optin' },
          // { key: 'opt_ins', label: 'Subscribers' },
          // { key: 'redemptions', label: 'Redemptions' },
          // { key: 'conversion_rate', label: 'Conversion %' },
          // { key: 'total_revenue', label: 'Total Revenue' },
          { key: 'actions', thStyle: { width: '50px'} },
        ],
        queries: {
          per_page: 10,
          page: 1,
          search: null,
          sort_by: 'id',
          sort_desc: true,
          sort_direction: 'asc',
        },
      },
      loadingSubmit: false,
      showKeywordModal: false,
      showDeleteModal: false,
      selectedKeyword: null,
      loadingKeywords: false,
      loadingRemove: null,
      keyword: null,
    }
  },

  computed: {
    totalKeywords() {
      return this.$store.getters['keyword/total']
    },

    keywords() {
      return this.$store.getters['keyword/all'] || []
    },
    user() {
      return this.$store.getters['auth/user']
    },

  },

  mounted() {
    this.getKeywords()

  },

  methods: {
    newKeyword() {
      this.keyword = {
        name: '',
        double_optin: true,
        double_optin_email: this.user.business.double_optin_email,
        optin_email_redirect_url: '',
      }
      this.selectedKeyword = null
      this.showKeywordModal = true
    },

    submitData() {
      this.loadingSubmit = true
      if (!this.selectedKeyword)
      {
        this.$store
          .dispatch('keyword/create', this.keyword)
          .then(() => {
            this.showKeywordModal = false
            this.keyword = null
            this.$refs.keywordForm.reset()
            this.loadingSubmit = false
            this.getKeywords();
          })
          .catch(() => {
            this.loadingSubmit = false
          })
      } else {
        this.$store
          .dispatch('keyword/update', this.keyword)
          .then(() => {
            this.showKeywordModal = false
            this.keyword = null
            this.$refs.keywordForm.reset()
            this.loadingSubmit = false
            this.getKeywords();
          })
          .catch(() => {
            this.loadingSubmit = false
          })
      }
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getKeywords()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getKeywords()
    },

    getKeywords() {
      this.loadingKeywords = true

      this.$store
        .dispatch('keyword/getAll', this.datatable.queries)
        .then(() => {
          this.loadingKeywords = false
        })
        .catch(() => {
          this.loadingKeywords = false
        })
    },

    edit(keyword) {
      this.keyword = {
        ...keyword
      }
      this.selectedKeyword = keyword
      this.showKeywordModal = true
    },

    destroy(keyword) {
      this.showDeleteModal = true
      this.selectedKeyword = keyword
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedKeyword.id

      this.$store
        .dispatch('keyword/destroy', this.selectedKeyword.id)
        .then(() => {
          this.loadingRemove = null
          this.getKeywords();
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>

